import { forwardRef } from "react";

import { Anchor, Box, BoxProps, Center, createStyles, ScrollArea } from "@mantine/core";
import { Trans, useTranslation } from "react-i18next";

import Card from "@Components/Cards/Card";
import NotableChipRow from "@Components/Cards/Card/NotableChipRow";
import ErrorBox from "@Components/ErrorBox/ErrorBox";
import { GoToLink, LinkRow } from "@Components/GoToLink";
import InfoButton from "@Components/InfoButton";
import PageSection from "@Components/PageSection/PageSection";
import { DATE_FORMAT } from "@Lib/constants";
import { useGetNotableProjectsBySector } from "@Lib/hooks/sectorCompass";
import { useFiltersStore } from "@Lib/store/filters/data";
import { getFormattedDate } from "@Lib/utils/date";
import { getSupportMailToHref } from "@Lib/utils/formatters";
import { generateLinkUrl } from "@Lib/utils/routes";

const useStyles = createStyles(theme => ({
  content: {
    height: "100%",
    paddingTop: theme.spacing.sm,
    marginInline: theme.spacing.sm,
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: theme.spacing.sm,
    [theme.fn.smallerThan("lg")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.fn.smallerThan("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
}));

type NotableProjectsSectionProps = BoxProps & {
  sectorId: number;
  sectorPath: string[];
};

const NotableProjectsSection = forwardRef<HTMLDivElement, NotableProjectsSectionProps>(
  ({ sectorId, sectorPath, ...boxProps }, ref) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const { data, isLoading, isError } = useGetNotableProjectsBySector(sectorId);

    const setTaxonomyFilter = useFiltersStore(state => state.setTaxonomyFilter);
    const clearFilters = useFiltersStore(state => state.clearFilters);

    const goto = generateLinkUrl("projects");

    const handleLinkClick = () => {
      clearFilters();
      setTaxonomyFilter({
        action: "add",
        filterField: "sector_id",
        localField: "companySectorIdsLocal",
        value: sectorPath.join("_"),
      });
    };

    return (
      <PageSection
        ref={ref}
        {...boxProps}
        title={t("sectorCompassPage.sections.notable.title")}
        isPreview
        infoButton={
          <InfoButton
            withinPortal
            position="bottom-start"
            contentTitle={t("sectorCompassPage.sections.deployment.notableInfoTitle")}
            content={
              <Trans
                i18nKey={"sectorCompassPage.sections.deployment.notableInfoContent"}
                components={{
                  a: <Anchor href={getSupportMailToHref()} />,
                }}
              />
            }
          />
        }
      >
        {isLoading ? (
          <Center className={classes.content}>{t("sectorCompassPage.sections.notable.loading")}</Center>
        ) : isError ? (
          <ErrorBox text={t("sectorCompassPage.sections.notable.errorText")} />
        ) : (
          <ScrollArea className={classes.content}>
            <Box className={classes.grid}>
              {data.map(({ id, name, companies, revisedDate, notableStatusName }) => {
                const { name: companyName, logo } = companies[0];
                const date = getFormattedDate(revisedDate, DATE_FORMAT);

                return (
                  <Card
                    key={id}
                    chips={<NotableChipRow status={notableStatusName} />}
                    imgSrc={logo}
                    title={name}
                    subtitle={companyName}
                    date={t("sectorCompassPage.sections.notable.card.revisedDate", { date })}
                    linkText={t("sectorCompassPage.sections.notable.card.link")}
                    goto={generateLinkUrl("projectById", id)}
                  />
                );
              })}
            </Box>
          </ScrollArea>
        )}
        <Box onClick={handleLinkClick}>
          <LinkRow>
            <GoToLink to={goto} text={t("sectorCompassPage.sections.notable.link")} />
          </LinkRow>
        </Box>
      </PageSection>
    );
  }
);
export default NotableProjectsSection;
