import { type ComponentType } from "react";

import { useAuthContext } from "@Context/Auth";
import { isFeatureAllowed, type FeatureFlagNames } from "@Lib/config/featureFlags";

export type WithAccessWrapperProps = {
  featureFlagKey?: FeatureFlagNames;
};

function withAccessWrapper<P extends WithAccessWrapperProps>(Component: ComponentType<P>) {
  return function WithAccessWrapper({ featureFlagKey, ...props }: P) {
    const { user } = useAuthContext();

    if ((featureFlagKey && !isFeatureAllowed(featureFlagKey, user)) || !user) {
      return null;
    }

    return <Component {...(props as P)} />;
  };
}

export default withAccessWrapper;
