import { lazy } from "react";

import { Navigate, type RouteObject } from "react-router-dom";

import Loader from "@Components/Loader/Loader";
import { ROUTES } from "@Lib/constants";

const CompaniesPage = lazy(() => import("@Pages/CompaniesPage"));
const DealsPage = lazy(() => import("@Pages/DealsPage"));
const InvestorsPage = lazy(() => import("@Pages/InvestorsPage"));
const FundsPage = lazy(() => import("@Pages/FundsPage"));
const ProjectsPage = lazy(() => import("@Pages/ProjectsPage"));

const PublicLayout = lazy(() => import("@Components/layouts/PublicLayout/PublicLayout"));
const CapitalStackPage = lazy(() => import("@Pages/CapitalStackPage"));

const NotAuthenticatedLayout = lazy(() => import("@Components/layouts/NotAuthenticatedLayout/NotAuthenticatedLayout"));
const LoginPage = lazy(() => import("@Pages/LoginPage"));
const SignupPage = lazy(() => import("@Pages/SignupPage"));
const ResetPasswordPage = lazy(() => import("@Pages/ResetPasswordPage"));
const NotFoundPage = lazy(() => import("@Pages/NotFoundPage"));
const MethodologyLayout = lazy(() => import("@Components/layouts/Methodology/MethodologyLayout"));
const FAQPage = lazy(() => import("@Pages/FAQPage"));
const DefinitionsPage = lazy(() => import("@Pages/DefinitionsPage"));
const MainLayout = lazy(() => import("@Components/layouts/MainLayout/MainLayout"));
const TablesLayout = lazy(() => import("@Components/layouts/DataRoute/TablesLayout/TablesLayout"));
const HomePage = lazy(() => import("@Pages/HomePage"));
const OrganizationDetailsRedirect = lazy(() => import("@Pages/OrganizationDetailsRedirect"));
const SightlineRedirect = lazy(() => import("@Pages/SightlineRedirect"));
const OrganizationDetailsPage = lazy(() => import("@Pages/OrganizationDetailsPage"));
const ProjectDetailsPage = lazy(() => import("@Pages/ProjectDetailsPage"));
const SightlinesPage = lazy(() => import("@Pages/SightlinesPage"));
const ReportsPage = lazy(() => import("@Pages/ReportsPage"));
const SectorCompassesPage = lazy(() => import("@Pages/SectorCompassesPage"));
const SectorCompassPage = lazy(() => import("@Pages/SectorCompassPage"));
const SightlinePage = lazy(() => import("@Pages/SightlinePage"));
const ReportPage = lazy(() => import("@Pages/ReportPage"));
const InvestmentDashPage = lazy(() => import("@Pages/InvestmentDashPage"));

export const routes: RouteObject[] = [
  {
    path: ROUTES.root,
    element: <NotAuthenticatedLayout />,
    children: [
      {
        index: true,
        element: <Loader />,
      },
      {
        path: ROUTES.login,
        element: <LoginPage />,
      },
      {
        path: ROUTES.signup,
        element: <SignupPage />,
      },
      {
        path: ROUTES.resetPass,
        element: <ResetPasswordPage />,
      },
    ],
  },
  {
    path: ROUTES.public,
    element: <PublicLayout />,
    children: [
      {
        path: ROUTES.capitalStack,
        element: <CapitalStackPage />,
      },
    ],
  },
  {
    element: <MainLayout />,
    children: [
      {
        path: ROUTES.home,
        element: <HomePage />,
      },
      {
        path: ROUTES.data,
        element: <TablesLayout />,
        children: [
          {
            index: true,
            element: <Navigate to={ROUTES.deals} replace />,
          },
          {
            path: ROUTES.deals,
            element: <DealsPage />,
          },
          {
            path: ROUTES.companies,
            element: <CompaniesPage />,
          },

          {
            path: ROUTES.investors,
            element: <InvestorsPage />,
          },
          {
            path: ROUTES.funds,
            element: <FundsPage />,
          },
          {
            path: ROUTES.projects,
            element: <ProjectsPage />,
          },
        ],
      },
      {
        path: ROUTES.data,
        children: [
          {
            path: ROUTES.companyById,
            element: <OrganizationDetailsRedirect />,
          },
          {
            path: ROUTES.investorById,
            element: <OrganizationDetailsRedirect />,
          },
          {
            path: ROUTES.organizationById,
            element: <OrganizationDetailsPage />,
          },
          {
            path: ROUTES.projectById,
            element: <ProjectDetailsPage />,
          },
        ],
      },
      {
        path: ROUTES.research,
        children: [
          {
            index: true,
            element: <Navigate to={ROUTES.compass} replace />,
          },
          {
            path: ROUTES.compass,
            element: <SectorCompassesPage />,
          },
          {
            path: ROUTES.compassById,
            element: <SectorCompassPage />,
          },
        ],
      },
      {
        path: ROUTES.insights,
        children: [
          { index: true, element: <Navigate to={ROUTES.sightlines} replace /> },
          { path: ROUTES.insightById, element: <SightlineRedirect /> },
        ],
      },
      {
        path: ROUTES.sightlines,
        children: [
          {
            index: true,
            element: <SightlinesPage />,
          },
          {
            path: ROUTES.sightlineById,
            element: <SightlinePage />,
          },
        ],
      },
      {
        path: ROUTES.reports,
        children: [
          { index: true, element: <ReportsPage /> },
          { path: ROUTES.reportsById, element: <ReportPage /> },
        ],
      },
      {
        path: ROUTES.investment,
        children: [
          {
            index: true,
            element: <InvestmentDashPage />,
          },
        ],
      },
      {
        path: ROUTES.methodology,
        element: <MethodologyLayout />,
        children: [
          {
            index: true,
            element: <Navigate to={ROUTES.faq} replace />,
          },
          {
            path: ROUTES.faq,
            element: <FAQPage />,
          },
          {
            path: ROUTES.definitions,
            element: <DefinitionsPage />,
          },
        ],
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
];
